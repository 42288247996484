<template>
  <div class="comments">
    <div class="action-bar">
      <el-input v-model="keyword" placeholder="输入关键字搜索留言信息" class="search" @keyup.enter.native="search">
        <el-button icon="el-icon-search" @click="search" slot="append"></el-button>
      </el-input>
    </div>
    <el-card shadow="hover">
      <el-table :data="list" size="mini" v-loading="loading">
        <el-table-column label="ID" prop="id" width="80"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="联系方式" prop="contact"></el-table-column>
        <el-table-column label="留言内容" prop="message"></el-table-column>
        <el-table-column label="IP" prop="ip"></el-table-column>
        <el-table-column label="时间" prop="created_at"></el-table-column>
      </el-table>

      <el-pagination
        :currentChange="currentChange"
        :total="total"
        :layout="pageLayout"
        :page-size="pageSize"
        background></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { commentsList } from '@/request/modules/comments'
import { PAGE_LAYOUT, PAGE_SIZE } from '@/config/app'

export default {
  data () {
    return {
      list: [],
      loading: false,
      page: 1,
      total: 0,
      keyword: '',
      pageLayout: PAGE_LAYOUT,
      pageSize: PAGE_SIZE
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      commentsList({ keyword: this.keyword, page: this.page }).then(resp => {
        this.list = resp.list
        this.total = resp.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    search () {
      this.currentChange(1)
    },
    currentChange (page) {
      this.page = page
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
